import React from 'react';
import Products from './Products/Products'
function App() {
  return (
    <div className="layout">
      <Products />
    </div>
  );
}

export default App;
